.App-resume-button {
    background: transparent;
    align-self: center;
    position: relative;
    margin-top: 50px;
    border: 0px;
    width: auto;
    font-family: "Lato", Arial, Helvetica,  sans-serif;
    font-size: 1.6em;
    line-height: 1.7em;
    border-radius: 15px;
    color: rgb(255,255,255);
    text-shadow: rgba(0, 0, 0, 1) 2px 2px 6px;
    letter-spacing: 3px;
}

.App-resume-button:before {
    content: '[';
    left: -15px;
    font-size: 1.1em;
    line-height: 1.4em;
}

.App-resume-button:after {
    content: ']';
    right: -15px;
    font-size: 1.1em;
    line-height: 1.4em;
}

.App-resume-button:after, .App-resume-button:before {
    position: absolute;
    top: 0;
    animation-name: opacity;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}



@keyframes opacity {
    0%, 100% {opacity:0;}
    50% {opacity:1;}
}

@media only screen and (max-width: 768px) {
    .App-resume-button {
        font-size: 1em;
        font-weight: 700;
        margin-bottom: 150px;
        margin-top: 0;
    }
}