* {
    margin:0;
    padding:0;
    background:transparent;
}

.content {
    text-align: left;
}

.container {
    width: max-content;
    height: max-content;

}

.word {
    padding-right: 0;
    font-size: 1em;
    width: max-content;
    overflow: hidden;
    opacity:0;
    position:absolute;
    top:44.5%;
    left:25%;
}

.w1 {
    animation: w1anim 20s infinite;
}

.w2 {
    animation: w2anim 20s infinite;
}

.w3 {
    animation: w3anim 20s infinite;
}

.w4 {
    animation: w4anim 20s infinite;
}

.w5 {
    animation: w5anim 20s infinite;
}



@keyframes w1anim {
    0%{
        opacity: 0;
    }
    10%{
        opacity: 1;
    }
    20% {
        opacity:0;
    }
}


@keyframes w2anim {
    20%{
        opacity: 0;
    }
    30%{
        opacity: 1;
    }
    40% {
        opacity:0;
    }
}

@keyframes w3anim {
    40%{
        opacity: 0;
    }
    50%{
        opacity: 1;
    }
    60% {
        opacity:0;
    }
}

@keyframes w4anim {
    60%{
        opacity: 0;
    }
    70%{
        opacity: 1;
    }
    80% {
        opacity:0;
    }
}

@keyframes w5anim {
    80%{
        opacity: 0;
    }
    90%{
        opacity: 1;
    }
    100% {
        opacity:0;
    }
}

@media only screen and (max-width: 768px ) {
    .word{
        top: 56.5%;
    }

    .animation-wrapper {
        margin-top: 30px;
    }
}

