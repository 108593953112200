.global {
    display: flex;
    flex-direction: column;
    background-color: #f7f7f7;
    color: #666;
}

.App {
    text-align: center;
    overflow: hidden !important;

}

.home {
    height: 100vh;
    width: 100vw;
}

/* Text Heading Formatting */
.intro {
    justify-content: center;
    height: auto;
    box-sizing: border-box;
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    font-family: "Lato", Arial, Helvetica,  sans-serif;
    font-weight: 700;
    color: rgb(255,255,255);
    text-shadow: rgba(0, 0, 0, 1) 2px 2px 6px;
    margin-top: 250px;
}

.intro-container {
    position: relative;
    font-size: 2.5rem;
}

.section-heading {
    letter-spacing: 6px;
    text-transform: uppercase;
    margin-top: 100px;
    font-weight: bold;
}

.navi-container {
    font-size: 32px;
    padding-bottom: 0;
    display: flex;
    flex-direction: row;
}

/* Mobile Interfaces */
@media only screen and (max-width: 768px) {
    .home {
        height: 80%;
    }

    .intro {
        margin-top: 120px;
        margin-bottom: 50px;
    }

    .intro-container {
        font-size: 1.5em;
    }

    .dlbtn {
        margin-top: 70px;
    }

    .section-heading {
        text-align: center;
    }
}

/* Mobile Interfaces */
@media only screen and (max-width: 960px) {
    .navi-container {
        justify-content: center;
    }
}