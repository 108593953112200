.footer-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 5em;
    background-color: rgba(170, 170, 170, 0.2) ;
}


/* Mobile Interfaces */
@media only screen and (max-width: 960px) {
    .footer-container {
        flex-direction: column;
        padding-left: 10px;
        padding-right: 10px;
        text-align: center;
    }
}