.navbar .navbar-expand {
    padding-bottom: 0;
    padding-top: 0;
}

.header-image {
    max-width: 1em;
    height: auto;
    padding: 1px;
}

.header-navbar {
    background: rgba(0, 0, 0, 0.75) none repeat scroll 0% 0%;
}

.my-modal {
    animation: 0.45s ease 0s 1 normal none running;
    background-color: rgb(0, 0, 0);
    top: 0;
    color: white;
}

.my-modal-body {
    background-color: rgb(0, 0, 0);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal-content {
    border: 0px transparent !important;
}

.modal-links {
    text-decoration: none !important;
    color: white;
    text-transform: uppercase;
    letter-spacing: 3px;
    line-height:18pt;
    font-size: 14pt;
    margin-bottom: 20px;
}

a.modal-links:hover {
    cursor: pointer;
    color: white;
    font-size: larger;
}
