.skill-title {
    font-weight: bold;
}

.skill-table {
    color: #666;
    margin-top: 100px;
    padding-bottom: 270px;
}

.skill-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
}


@media only screen and (max-width: 768px) {
    .skill-table {
        color: #666;
        padding-bottom: 530px;
    }
}